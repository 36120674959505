<script setup>
import { ref, onMounted } from 'vue';
import currencies from "@/data/currencies";

const url = 'https://v6.exchangerate-api.com/v6/1de4ba7522820004c2cd1675/latest/';

let savedData = JSON.parse(localStorage.getItem('currencyData'));
if (!savedData) {
  savedData = {
    from: 0,
    to: 0,
    fromCode: 'USD',
    toCode: 'EUR',
    rate: 0,
    lastUpdated: new Date().toLocaleString(),
    history: {}
  };
  localStorage.setItem('currencyData', JSON.stringify(savedData));
}

const fromAmount = ref(savedData.from);
const toAmount = ref(savedData.to);
const fromCurrency = ref(currencies.find(currency => currency.code === savedData.fromCode));
const toCurrency = ref(currencies.find(currency => currency.code === savedData.toCode));
const conversionRate = ref(savedData.rate);
const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'C'];
const showCurrencies = ref(false);
const selectedValue = ref('');
const filteredCurrencies = ref(currencies);

const pressKey = (key) => {
  if (key === 'C') {
    fromAmount.value = 0;
  } else {
    if (fromAmount.value === 0 && key !== '.') {
      fromAmount.value = '';
    }
    fromAmount.value += key;
  }
  convertCurrency();
};

const convertCurrency = () => {
  const amount = parseFloat(fromAmount.value);
  if (!isNaN(amount)) {
    toAmount.value = amount * conversionRate.value;
  } else {
    toAmount.value = 0;
  }
  savedData.from = fromAmount.value;
  savedData.to = toAmount.value;
  localStorage.setItem('currencyData', JSON.stringify(savedData));
};

const close = () => {
  showCurrencies.value = false;
};

const open = (value) => {
  filteredCurrencies.value = currencies;
  showCurrencies.value = true;
  selectedValue.value = value;

};

const selectCurrency = (code) => {
  const currency = currencies.find(currency => currency.code === code);
  if (currency) {
    if (selectedValue.value === 'from') {
      fromCurrency.value = currency;
    } else {
      toCurrency.value = currency;
    }
    selectedValue.value = '';
    savedData.fromCode = fromCurrency.value.code;
    savedData.toCode = toCurrency.value.code;
    close();
    fetchData();
  }
};

const backspace = () => {
  if (fromAmount.value === 0 || fromAmount.value === '') {
    return;
  }
  fromAmount.value = fromAmount.value.slice(0, -1);
  if (fromAmount.value === '') {
    fromAmount.value = 0;
  }
  convertCurrency();
};

const exchangeCurrencies = () => {
  const temp = fromCurrency.value;
  fromCurrency.value = toCurrency.value;
  toCurrency.value = temp;

  savedData.fromCode = fromCurrency.value.code;
  savedData.toCode = toCurrency.value.code;
  localStorage.setItem('currencyData', JSON.stringify(savedData));

  fetchData();
};

const formatAmount = (value) => {
  let numericValue = value.replace(/[^0-9.-]/g, '');
  const parts = numericValue.split('.');
  parts[0] = parseInt(parts[0]).toLocaleString('en-US');
  numericValue = parts.join('.');
  return numericValue;
};

const filterCurrencies = (event) => {
  const value = event.target.value.toLowerCase();
  filteredCurrencies.value = currencies.filter(currency => {
    return currency.name.toLowerCase().includes(value) || currency.code.toLowerCase().includes(value);
  });
};

const fetchData = async () => {
  if (savedData.lastUpdated && new Date().getTime() - new Date(savedData.lastUpdated).getTime() < 3600000
      && savedData.fromCode === fromCurrency.value.code
      && savedData.toCode === toCurrency.value.code
      && savedData.history[`${fromCurrency.value.code}-${toCurrency.value.code}`] !== undefined
  ){
    conversionRate.value = savedData.history[`${fromCurrency.value.code}-${toCurrency.value.code}`];
    convertCurrency();
    return;
  }

  fetch(`${url}${fromCurrency.value.code}`)
      .then(response => response.json())
      .then(data => {
        conversionRate.value = data.conversion_rates[toCurrency.value.code];
        savedData.lastUpdated = new Date().toLocaleString();
        savedData.rate = conversionRate.value;
        savedData.history[`${fromCurrency.value.code}-${toCurrency.value.code}`] = conversionRate.value;
        localStorage.setItem('currencyData', JSON.stringify(savedData));
      }).finally(() => {
    convertCurrency();
  });
};

onMounted(() => {
  fetchData();
});
</script>

<template>
  <div class="currency-converter">
      <div class="currency-display">
        <div class="currency from-currency">
          <div class="currency-code" @click="open('from')"><img class="flag" :src="fromCurrency.flag" :alt="`${fromCurrency.code} Flag`"> {{ fromCurrency.code }}</div>
          <div class="amount">{{ formatAmount(fromAmount.toLocaleString()) }}</div>
        </div>
        <div class="currency to-currency">
          <div class="currency-code" @click="open('to')"><img class="flag" :src="toCurrency.flag" :alt="`${toCurrency.code} Flag`"> {{ toCurrency.code }}</div>
          <div class="amount">{{ formatAmount(toAmount.toFixed(2)) }}</div>
        </div>
      </div>
      <div>
        <div class="actions">
          <button @click="exchangeCurrencies">⇅</button>
          <button @click="backspace">←</button>
        </div>
        <div class="keypad">
          <button v-for="n in keys" :key="n" @click="pressKey(n)">
            {{ n }}
          </button>
        </div>
        <div class="conversion-rate">
          1 {{ fromCurrency.code }} = {{ conversionRate }} {{ toCurrency.code }} | {{ savedData.lastUpdated }}
        </div>
      </div>
      <div :class="{currencies: true, active: showCurrencies}">
        <div class="codes-container">
          <div class="close" @click="close()">×</div>
          <div class="search-container">
            <input type="text" placeholder="Search for a currency" @keyup="filterCurrencies">
          </div>
          <div class="codes">
            <div class="code" v-for="currency in filteredCurrencies" :key="currency.code" @click="selectCurrency(currency.code)">
              <img class="flag" :src="currency.flag" :alt="`${currency.name} Flag`">
              <div class="name">{{ currency.name }}</div>
              <div class="currency-code">{{ currency.code }}</div>
            </div>
        </div>
        </div>
      </div>
    </div>
</template>

<style scoped>

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.actions button {
  width: 100%;
  padding: 10px;
  font-size: 25px;
  background-color: #8f5919;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.currencies {
  position: absolute;
  top: 0;
  background: #333;
  left: 0;
  padding: 0 10px;
  display: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.currencies.active {
  display: block;
}

.currencies .close {
  cursor: pointer;
  color: white;
  font-size: 27px;
  text-align: right;
}

.currencies .search-container {
  margin: 20px 0;
  width: 100%;
}

.currencies .search-container input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  background: #545454;
  color: white;
}

.currencies .codes {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 80vh;
  overflow-y: scroll;
}

.currencies .code {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: #444;
}

.currencies .currency-code {
  margin-left: auto;
}

.currency-converter {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: auto;
  text-align: center;
  padding: 20px 20px 10px;
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.currency-display {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 25px;
}

.currency {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.flag {
  width: 50px;
  height: auto;
  margin-right: 10px;
  border-radius: 5px;
}

.currency-code {
  font-size: 18px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.amount {
  font-size: 24px;
  font-weight: bold;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.keypad button {
  padding: 10px;
  font-size: 20px;
  background-color: #555;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  aspect-ratio: 1.3;
}

.keypad button:active {
  background-color: #777;
}

.conversion-rate {
  margin-top: 10px;
  font-size: 13px;
}

</style>
